// Init
import React from "react";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
// Files
import Index from "./routes/index";
import "./css/index.css";

// Component
function App() {
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Sam Sahotra Foundation",
    url: "https://www.samsahotra.org/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.samsahotra.org/{search_term_string}'about-us'",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <div className="App">
      
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* toastify Container for Notification */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        transition={Flip}
      />

      {/* Routes */}
      <Index />
    </div>
  );
}

// Export
export default App;
