// Init
import React, { Fragment, useState, useEffect } from "react";

// Importing Components
import ChristmasPromotionModal from "../components/Christmass";
import VideoCard from "../components/VideoCard";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Who from "../components/whoWe";
import Support from "../components/support";
import OurStory from "../components/OurStory";
import SuccessStory from "../components/SuccesMain";
import Partner from "../components/Partner";
import Cause from "../components/Cause";
import NewUpdate from "../components/newUpadate";
import Jerwalanew from "../components/jerwalanew.js";
import Slider from "../slider";
import Footer from "../components/Footer";
import Services from "../components/Services";
import MainSliderTwo from "../components/MainSlide";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import VideoCardTwo from "../components/Video";
// Home Component
export default function Home() {
  const [showPopup, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem("popupDisplayed", "true");
  };

  useEffect(() => {
    const popupDisplayed = localStorage.getItem("popupDisplayed");
    if (!popupDisplayed) {
      // If the popup hasn't been displayed before, show it
      setShowPopup(true);
    }
  }, []);
  return (
    <>
    <div class="gfm-embed" data-url="
https://www.gofundme.com/f/9gnnn-hurricane-helene/widget/large?sharesheet=firstTime&attribution_id=sl:684363ef-dcf2-41fc-8924-c5b394a057e1"></div><script
defer src="
https://www.gofundme.com/static/js/embed.js"></script>
    <Fragment>
      <Helmet>
        <title>Join Sam Sahotra’s Mission | Healthcare & Poverty Relief</title>

        <link rel="canonical" href="https://www.samsahotra.org/" />
        <meta
          name="description"
          content="Be a part of Sam Sahotra’s journey to create a better world. Help provide essential healthcare, quality education, and poverty relief."
        />
      </Helmet>
      {/* <ChristmasPromotionModal /> */}
      {/* Your main content goes here */}
      <Header />
      <MainSliderTwo />
      <VideoCardTwo />
      <Cause />
      {/* <VideoCard/> */}
      {/* <Services/> */}
      {/* <Slider /> */}
      {/* <HeroSection /> */}
      <Who />
      <Jerwalanew />

      {/* <Support/> */}
      <OurStory />
      <SuccessStory />
      {/* <Partner/> */}
      <NewUpdate />
      <Footer />
    </Fragment>
    </>
  );
}
