import React from 'react'
import JoinS from '../components/Join-ssf/joinHome'
import Become from '../components/Join-ssf/becomeVolunter'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
function Join() {
  return (
    <div>
      <Helmet>
        <title>Our Mission to Create Lasting Change | Sam Sahotra Foundation </title>
        <link rel="canonical" href="https://www.samsahotra.org/join" />
        <meta name='description' content="Support our mission to improve lives with healthcare, education, and poverty relief. Helping communities through education, healthcare, and poverty relief." />
      </Helmet>
    <JoinS/>
    <Become/>
    <Footer/>

    </div>
  )
}

export default Join